import React, { createContext, useCallback, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { useExperiment } from "~/components/experiments/hooks/useExperiment";
import { useGetMigrationStatusQuery } from "~/graphql/hooks/useGetMigrationStatusQuery";
import { usePerformPdsCheckMutation } from "~/graphql/hooks/usePerformPdsCheckMutation";
import {
  AccountMigrationInfoNonMigratablePatientReason,
  AccountMigrationRelationship,
  AccountMigrationStatus,
  MigrationActivationStatus,
  PatientMigration
} from "~/graphql/types/global";
import {
  getMigrationStatus_account_migrationInfo_migratablePatients,
  getMigrationStatus_account_migrationInfo_nonMigratablePatients_patient
} from "~/graphql/types/getMigrationStatus";

export type Patient =
  getMigrationStatus_account_migrationInfo_migratablePatients &
    getMigrationStatus_account_migrationInfo_nonMigratablePatients_patient;

export type MigratablePatient = Omit<PatientMigration, "nominated"> & {
  patient: Patient;
  skipped: boolean;
};

export type NonMigratablePatient = {
  patient: Patient;
  reason: AccountMigrationInfoNonMigratablePatientReason;
};

export type SelectableMigrationTypes =
  | "NOMINATE"
  | "NO_NOMINATE"
  | "NO_MIGRATE";

type MigrationContextType = {
  hasNoks: boolean;
  migrationEnabledForAccount?: boolean;
  migrationStatus: AccountMigrationStatus;
  activationStatus: MigrationActivationStatus;
  activationLink: string | null;
  migrateFlowPath?: string;
  nominatedWithP2U: boolean;
  canOrder: boolean;
  trackExperimentEvents: (eventName: string[]) => void;
  reFetchMigrationStatus: () => Promise<AccountMigrationStatus>;
  migratablePatients: MigratablePatient[];
  nonMigratablePatients: NonMigratablePatient[];
  updatePatientDetails: (patient: Partial<MigratablePatient>) => void;
  marketingConsent: boolean;
  setMarketingConsent: (updatedMarketingConsent: boolean) => void;
  migrationConsent: boolean;
  setMigrationConsent: (updatedMigrationConsent: boolean) => void;
  scrConsent: boolean;
  setSCRConsent: (updatedSCRConsent: boolean) => void;
  reset: () => void;
};

type Props = {
  children?: React.ReactNode;
};

export const MigrationContext = createContext<MigrationContextType>({
  hasNoks: false,
  migrationStatus: AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN,
  activationStatus:
    MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN,
  activationLink: null,
  migrateFlowPath: undefined,
  nominatedWithP2U: false,
  canOrder: true,
  trackExperimentEvents: () => {},
  reFetchMigrationStatus: async () =>
    AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN,
  migratablePatients: [],
  nonMigratablePatients: [],
  updatePatientDetails: () => {},
  marketingConsent: true,
  setMarketingConsent: () => {},
  migrationConsent: false,
  setMigrationConsent: () => {},
  scrConsent: false,
  setSCRConsent: () => {},
  reset: () => {}
});

interface PatientRouteParams {
  patientId?: string;
}

// This is our context provider which we will wrap around the entire app
export const MigrationProvider: React.FC<Props> = ({ children }) => {
  // This is fairly hacky but without going through every route and
  // adding the provider we can't use router params properly at
  // this level in the app hierarchy
  const { pathname } = useLocation();
  const route = matchPath<PatientRouteParams>(pathname, {
    path: "*/(help|patient)/:patientId"
  });
  const patientId = route?.params?.patientId;
  const [patientsDetailsState, setPatientDetails] = useState<
    MigratablePatient[]
  >([]);
  const [marketingConsent, setMarketingConsent] = useState(true);
  const [migrationConsent, setMigrationConsent] = useState(false);
  const [scrConsent, setSCRConsent] = useState(false);

  const [performPdsCheck] = usePerformPdsCheckMutation({});

  const { data, refetch } = useGetMigrationStatusQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: async data => {
      // If this is a migration experiment and the user is in a pending or consented state
      // we want to attempt a PDS trace to see if their nomination will change, if so
      // we will refetch the migration status again to get the latest from our backend.
      if (
        !data?.account?.migrationInfo?.experiment?.isEnabled ||
        !(
          data?.account?.migrationInfo?.status ===
            AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_PENDING ||
          data?.account?.migrationInfo?.status ===
            AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_CONSENTED
        )
      ) {
        return;
      }

      const hasPDSTraced = await Promise.all(
        data?.account?.migrationInfo?.migratablePatients?.map(async p => {
          // This mutation handles limiting the number of PDS checks we can do per day
          // so we don't have to worry about rate limiting here.
          const pdsCheckData = await performPdsCheck({
            variables: {
              patientId: p.id,
              isMigrationCheck: true
            }
          });

          return (
            !pdsCheckData?.errors &&
            pdsCheckData?.data?.performPdsCheck?.patient
              ?.nominatedPharmacyUpdatedAt?.seconds !==
              p.nominatedPharmacyUpdatedAt?.seconds
          );
        })
      );

      if (hasPDSTraced.some(t => t)) {
        refetch();
      }
    },
    onError: error => {
      console.log(error);
    }
  });

  const patient: Patient | undefined =
    data?.account?.migrationInfo?.migratablePatients?.find(
      p => p.id === patientId
    ) ||
    data?.account?.migrationInfo?.nonMigratablePatients?.find(
      p => p.patient.id === patientId
    )?.patient;

  const migrationStatus =
    data?.account?.migrationInfo.status ||
    AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN;

  const activationStatus =
    data?.migrationActivationDetails?.status ||
    MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN;
  const activationLink = data?.migrationActivationDetails?.link || null;

  const experiment = data?.account?.migrationInfo.experiment;
  const { trackExperimentEvents } = useExperiment("lion-cohort-01");

  if (patientsDetailsState.length === 0 && !!data) {
    setPatientDetails(
      data.account?.migrationInfo?.migratablePatients.map(patient => {
        return {
          patient,
          id: patient.id,
          addressId: "",
          nominated: patient.isNominated,
          relationship: patient.isAccountHolder
            ? AccountMigrationRelationship.ACCOUNT_MIGRATION_RELATIONSHIP_OWNER
            : AccountMigrationRelationship.ACCOUNT_MIGRATION_RELATIONSHIP_UNKNOWN,
          skipped: false
        };
      }) || []
    );
  }

  const updatePatientDetails = (patient: Partial<PatientMigration>) => {
    setPatientDetails(pd =>
      pd.map(pat => {
        if (pat.id === patient.id) {
          return {
            ...pat,
            ...patient
          };
        } else {
          return pat;
        }
      })
    );
  };

  const reset = useCallback(() => {
    setPatientDetails([]);
    setMarketingConsent(true);
    setMigrationConsent(false);
    setSCRConsent(false);
  }, [
    setPatientDetails,
    setMarketingConsent,
    setMigrationConsent,
    setSCRConsent
  ]);

  return (
    <MigrationContext.Provider
      value={{
        hasNoks:
          (data?.account?.migrationInfo?.migratablePatients?.length || 0) +
            (data?.account?.migrationInfo?.nonMigratablePatients?.length || 0) >
          1,
        migrationEnabledForAccount: experiment?.isEnabled,
        migrationStatus: migrationStatus,
        activationStatus,
        activationLink,
        migrateFlowPath: `/patient/${
          patientId ||
          patientsDetailsState?.find(
            p =>
              p.relationship ===
              AccountMigrationRelationship.ACCOUNT_MIGRATION_RELATIONSHIP_OWNER
          )?.id ||
          patientsDetailsState?.at(0)?.id
        }/medication/migrate`,
        nominatedWithP2U: patient?.isNominatedWithP2U || false,
        canOrder: data?.account?.migrationInfo?.canOrder || false,
        trackExperimentEvents,
        reFetchMigrationStatus: async () => {
          return (
            (await refetch())?.data?.account?.migrationInfo?.status ||
            AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN
          );
        },
        migratablePatients: patientsDetailsState,
        nonMigratablePatients:
          data?.account?.migrationInfo?.nonMigratablePatients || [],
        updatePatientDetails,
        marketingConsent,
        setMarketingConsent,
        migrationConsent,
        setMigrationConsent,
        scrConsent,
        setSCRConsent,
        reset
      }}
    >
      {children}
    </MigrationContext.Provider>
  );
};

// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions
} from "@apollo/client";
import { getMigrationStatus } from "../types/getMigrationStatus";

export const useGetMigrationStatusQuery = (
  opts: QueryHookOptions<getMigrationStatus, {}>
) => {
  return useQuery<getMigrationStatus, {}>(getMigrationStatusQuery, opts);
};

export const getMigrationStatusQuery = gql`
  query getMigrationStatus {
    account {
      id
      migrationInfo {
        status
        canOrder
        experiment {
          isEnabled
        }
        migratablePatients {
          ...MigrationStatusPatient
        }
        nonMigratablePatients {
          patient {
            ...MigrationStatusPatient
          }
          reason
        }
      }
    }
    migrationActivationDetails {
      link
      status
    }
  }

  fragment MigrationStatusPatient on Patient {
    id
    firstName
    lastName
    isNominated
    nominatedPharmacyUpdatedAt {
      seconds
    }
    isNominatedWithP2U
    isAccountHolder
  }
`;

export function refetchGetMigrationStatusQuery() {
  return {
    query: getMigrationStatusQuery
  };
}

export const useLazyGetMigrationStatusQuery = (
  opts: LazyQueryHookOptions<getMigrationStatus, {}>
) => {
  return useLazyQuery<getMigrationStatus, {}>(getMigrationStatusQuery, opts);
};
